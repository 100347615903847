const API_VERSION = '1'
const API_VERSION_URL = '/v' + API_VERSION
const ANONYMOUS_CUSTOMERS_URL = '/anonymous_customers'
const CUSTOMERS_URL = '/customers'
const CUSTOMER_URL = API_VERSION_URL + '/customer'
const LIST_ITEMS_URL = API_VERSION_URL + '/list'
const ADDRESSES_URL = CUSTOMER_URL + '/addresses'
const ORDERS_URL = CUSTOMER_URL + '/orders'
const FCM_URL = CUSTOMER_URL + '/fcm_tokens'
const COMPANIES_URL = API_VERSION_URL + '/companies'
const LOCALITIES_URL = COMPANIES_URL + '/:company_id/localities'
const BRANCHES_URL = LOCALITIES_URL + '/:locality_id/branches'
const SHOW_BRANCH_URL = COMPANIES_URL + '/:company_id/branches'
const CATEGORIES_URL = COMPANIES_URL + '/:company_id/categories'
const PRODUCTS_URL = COMPANIES_URL + '/:company_id/products'
const CAMPAIGNS_URL = COMPANIES_URL + '/:company_id/campaigns'
const ACCOMPANIMENTS_URL = COMPANIES_URL + '/:company_id/accompaniments'
const SECTIONS_URL = API_VERSION_URL + '/branches/:branch_id/sections'
const PLACES_URL = API_VERSION_URL + '/sections/:section_id/places'
const RESERVATIONS_URL = COMPANIES_URL + '/:company_id/reservations'

export { ANONYMOUS_CUSTOMERS_URL, CUSTOMER_URL, ADDRESSES_URL, ORDERS_URL, COMPANIES_URL, BRANCHES_URL, CATEGORIES_URL, PRODUCTS_URL, LOCALITIES_URL, LIST_ITEMS_URL, CAMPAIGNS_URL, ACCOMPANIMENTS_URL, FCM_URL, SHOW_BRANCH_URL, SECTIONS_URL, PLACES_URL, RESERVATIONS_URL, CUSTOMERS_URL }
