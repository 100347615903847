import WemeikAPI from '../services/wemeik-api'
import Axios from 'axios'
import swal from 'sweetalert'
import { getLocalStorageValue, setCheckoutSettings, getSessionStorageValue } from '../utils/util'
import { HIDE_PRELOADER, SET_CUSTOMER_ADDRESS, SET_BRANCH, SET_BRANCHES, SET_COMPANY, SET_LOCALITY, SET_LOCALITIES, SET_POSITION, SET_PRODUCTS } from './actionTypes'

const showAlert = (snackbar, message, type = 'success') => {
  if (snackbar && snackbar !== undefined) {
    let snackSurface = document.querySelector('.mdc-snackbar .mdc-snackbar__surface')
    snackSurface.classList.remove('snackbar--error', 'snackbar--warning')
    switch (type) {
      case 'error':
        snackSurface.classList.add('snackbar--error')
        break
      case 'warning':
        snackSurface.classList.add('snackbar--warning')
        break
      default:
        break
    }

    snackbar.labelText = message
    snackbar.open()
  }
}

const loadCompanyInfo = (store, http) => {
  WemeikAPI.companies.show(http, process.env.REACT_APP_COMPANY_ID)
    .then((company) => {
      let link = document.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = '/themes/' + company.theme + '.css'
      document.querySelector('head').appendChild(link)
      link.onload = () => store.dispatch({ type: SET_COMPANY, company: company })
    })
    .catch((error) => {
      console.error('Error getting company info: ', error.response)
    })
}

const loadCategories = (store, http) => {
  WemeikAPI.categories.index(http, process.env.REACT_APP_COMPANY_ID)
    .then((categories) => {
      store.dispatch({ type: 'SET_CATEGORIES', categories: categories })
      if (categories && categories.length > 0) {
        store.dispatch({ type: 'SET_CATEGORY', categoryIndex: 0, dispatch: store.dispatch })
      }
    })
    .catch((error) => {
      console.error('Error getting categories: ', error.response)
      store.dispatch({ type: HIDE_PRELOADER })
    })
}

const loadProducts = (dispatch, http, params = {}) => {
  const defaultParams = Object.assign({}, { branch_id: getLocalStorageValue('branchId') }, params)
  WemeikAPI.products.index(http, process.env.REACT_APP_COMPANY_ID, defaultParams)
    .then((products) => {
      dispatch({ type: SET_PRODUCTS, products: products })
    })
    .catch((error) => {
      console.error('Error getting products: ', error.response)
    })
    .finally(() => {
      dispatch({ type: HIDE_PRELOADER })
    })
}

const loadBranches = (dispatch, http, localityId, position = {}) => {
  WemeikAPI.branches.index(http, process.env.REACT_APP_COMPANY_ID, localityId, position)
    .then((branches) => {
      dispatch({ type: SET_BRANCHES, branches: branches })
      if (branches.length > 0) {
        let selectedBranch = branches.find((b) => b.nearest)

        const branchId = parseInt(getLocalStorageValue('branchId'))
        if (branchId && (!selectedBranch || branchId !== selectedBranch.id)) {
          const branchIndex = branches.findIndex((b) => b.id === branchId)
          if (branchIndex >= 0) {
            selectedBranch = branches[branchIndex]
          }
        }

        dispatch({ type: SET_BRANCH, selectedBranch: (selectedBranch ? selectedBranch : branches[0]), dispatch: dispatch })
      }
      dispatch({ type: HIDE_PRELOADER })
    })
    .catch((error) => {
      console.error('Error getting Branch Offices: ', error)
      dispatch({ type: HIDE_PRELOADER })
    })
}

const loadLocalities = (dispatch, http, position = {}) => {
  WemeikAPI.localities.index(http, process.env.REACT_APP_COMPANY_ID, position)
    .then((localities) => {
      dispatch({ type: SET_LOCALITIES, localities: localities })
      if (localities.length > 0) {
        let selectedLocalityId = parseInt(getSessionStorageValue('localityId')) ? parseInt(getSessionStorageValue('localityId')) : localities[0].id
        if (!selectedLocalityId || localities.findIndex(l => l.id === selectedLocalityId) < 0) {
          const nearestLocalityId = localities.find((locality) => locality.nearest).id
          if (nearestLocalityId) {
            selectedLocalityId = nearestLocalityId
          }
        }

        dispatch({ type: SET_LOCALITY, localityId: selectedLocalityId, dispatch: dispatch })
      }
    })
    .catch((error) => {
      console.error('Error getting localities: ', error.response)
      dispatch({ type: HIDE_PRELOADER })
    })
}

const loadLastAddress = (dispatch, http) => {
  WemeikAPI.addresses.showLast(http)
    .then((address) => {
      dispatch({ type: SET_CUSTOMER_ADDRESS, customerAddress: address })
    }).catch((error) => {
      console.error('Error loading address: ', error.response)
    })
}

const setDefaultPlace = (dispatch, branches = []) => {
  const urlParams = new URLSearchParams(window.location.search)

  const sectionAndPlaceCode = urlParams.get('place_code')
  if (sectionAndPlaceCode) {
    setCheckoutSettings({ deliveryType: 'order_now', sectionAndPlaceCode: sectionAndPlaceCode })
  }

  const branchId = parseInt(urlParams.get('branch_id'))
  if (branchId && Array.isArray(branches) && branches.length > 0) {
    dispatch({ type: SET_BRANCH, selectedBranch: branches.find((b) => b.id === branchId) })
  }
}

const getCurrentPosition = (dispatch) => {
  navigator.geolocation.getCurrentPosition((position) => {
    dispatch({ type: SET_POSITION, position: { latitude: position.coords.latitude, longitude: position.coords.longitude }, dispatch: dispatch })
  }, (error) => {
    console.error('Error getting position from HTML5: ', error)
    getCurrentPositionFromGoogle(dispatch)
  })
}

const getCurrentPositionFromGoogle = (dispatch) => {
  Axios.post('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAO2sgburBqlqlW25qPIEiXWJlvkGj-qyM')
    .then((response) => {
      dispatch({ type: SET_POSITION, position: { latitude: response.data.location.lat, longitude: response.data.location.lng }, dispatch: dispatch })
    })
    .catch((error) => {
      console.error('Error getting position from Google API: ', error)
    })
}

const askGeolocation = (dispatch) => {
  swal({
    title: 'Por favor, compartenos tu ubicación',
    text: 'Así podremos enviarte tu pedido desde nuestra sucursal más cercana.',
    icon: 'info',
    buttons: {
      cancel: 'Cancelar',
      confirm: 'Compartir'
    },
    dangerMode: false
  })
    .then((accepted) => {
      if (accepted) {
        getCurrentPosition(dispatch)
      } else {
        getCurrentPositionFromGoogle(dispatch)
      }
    })
}

const calculateCustomerLocation = (dispatch) => {
  try {
    if (navigator.permissions) {
      navigator.permissions.query({
        name: 'geolocation'
      })
        .then((permission) => {
          if (permission.state === 'granted') {
            getCurrentPosition(dispatch)
          } else {
            askGeolocation(dispatch)
          }
        })
        .catch((error) => {
          console.error('Error', error)
        })
    } else {
      askGeolocation(dispatch)
    }
  } catch (error) {
    getCurrentPositionFromGoogle(dispatch)
  }
}

export { calculateCustomerLocation, loadBranches, loadCategories, loadCompanyInfo, loadLastAddress, loadLocalities, loadProducts, showAlert, setDefaultPlace }
